import "./ExcalidrawLogo.scss";

const LogoIcon = () => (
<svg viewBox="0 0 395.43 125.02" fill="#d27c22" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg"><path d="M42.86 111.62H17.77v-78.5c0-10.89 8.76-19.72 19.56-19.72h39.08c10.79 0 19.56 8.84 19.56 19.72v78.5H70.91c-1.78-6.11-7.39-10.57-14.02-10.57s-12.24 4.46-14.02 10.57Zm14.02-53.9c-8.07 0-14.62 6.61-14.62 14.74S48.81 87.2 56.88 87.2 71.5 80.59 71.5 72.46s-6.55-14.74-14.62-14.74Z"></path>
<path d="M151.05 85.2h-19.09l-3.59 10.65h-11.32l18.49-51.82h12.23l18.35 51.82h-11.74l-3.34-10.65Zm-3.02-8.93-6.47-20.39-6.68 20.39h13.15ZM184.94 64.95c-3.4 0-5.73 1.44-7 4.32-.66 1.52-.98 3.47-.98 5.84v20.74h-9.98V57.6h9.67v5.59c1.29-1.97 2.51-3.39 3.66-4.25 2.06-1.55 4.68-2.32 7.84-2.32 3.96 0 7.2 1.04 9.72 3.11 2.52 2.07 3.78 5.51 3.78 10.32v25.8h-10.27V72.54c0-2.02-.27-3.56-.81-4.64-.98-1.97-2.86-2.95-5.62-2.95Z"></path><path d="m219.92 85.8 7.91-28.27h10.65l-13.15 37.69c-2.53 7.27-4.54 11.77-6.01 13.52-1.48 1.75-4.43 2.62-8.86 2.62-.89 0-1.61 0-2.14-.02-.54-.01-1.35-.05-2.43-.12v-8.02l1.27.07c.98.05 1.92.01 2.81-.11.89-.12 1.64-.39 2.25-.81.59-.4 1.13-1.23 1.63-2.5s.71-2.04.62-2.32l-14.06-40.01h11.14l8.37 28.27Z"></path><path d="M258.71 88.36v7.49l-4.75.18c-4.73.16-7.97-.66-9.7-2.46-1.12-1.15-1.69-2.92-1.69-5.31V64.67h-7.5l2.16-7.14h5.34V46.84h9.91v10.69h6.22v7.14h-6.22v20.25c0 1.57.2 2.55.6 2.94.4.39 1.62.58 3.66.58.3 0 .63 0 .97-.02.34-.01.67-.03 1-.05ZM295.01 73.36v22.5h-10.23V72.55c0-2.06-.35-3.73-1.05-4.99-.91-1.78-2.65-2.67-5.2-2.67s-4.66.88-6.03 2.65-2.06 4.29-2.06 7.58v20.74h-9.98V44.22h9.98v18.32c1.45-2.23 3.13-3.78 5.04-4.66s3.92-1.32 6.03-1.32c2.37 0 4.52.41 6.45 1.23 1.93.82 3.52 2.07 4.76 3.76 1.05 1.43 1.7 2.9 1.93 4.41s.35 3.98.35 7.4ZM309.34 43.72v9.25h-10.16v-9.25h10.16Zm0 13.81v38.32h-10.16V57.53h10.16ZM331.68 64.95c-3.4 0-5.73 1.44-7 4.32-.66 1.52-.98 3.47-.98 5.84v20.74h-9.98V57.6h9.67v5.59c1.29-1.97 2.51-3.39 3.66-4.25 2.06-1.55 4.68-2.32 7.84-2.32 3.96 0 7.2 1.04 9.72 3.11s3.78 5.51 3.78 10.32v25.8h-10.27V72.54c0-2.02-.27-3.56-.81-4.64-.98-1.97-2.86-2.95-5.62-2.95ZM387.67 95.86h-12.09l-9.18-16.38-4.15 4.32v12.06h-9.84V44.22h9.84v27.91l12.48-14.41h12.41l-13.39 14.66 13.92 23.48Z"></path></svg>
);

const LogoText = () => (
  <svg
    viewBox="0 0 405.43 125.02" fill="#d27c22"
    xmlns="http://www.w3.org/2000/svg"
    className="ExcalidrawLogo-text"
  >

  </svg>
);

type LogoSize = "xs" | "small" | "normal" | "large" | "custom";

interface LogoProps {
  size?: LogoSize;
  withText?: boolean;
  style?: React.CSSProperties;
  /**
   * If true, the logo will not be wrapped in a Link component.
   * The link prop will be ignored as well.
   * It will merely be a plain div.
   */
  isNotLink?: boolean;
}

export const ExcalidrawLogo = ({
  style,
  size = "small",
  withText,
}: LogoProps) => {
  return (
    <div className={`ExcalidrawLogo is-${size}`} style={style}>
      <LogoIcon />
      {withText && <LogoText />}
    </div>
  );
};
